const states = [
  { "value": "andhra-pradesh", "label": "Andhra Pradesh" },
  { "value": "arunachal-pradesh", "label": "Arunachal Pradesh" },
  { "value": "assam", "label": "Assam" },
  { "value": "bihar", "label": "Bihar" },
  { "value": "chhattisgarh", "label": "Chhattisgarh" },
  { "value": "goa", "label": "Goa" },
  { "value": "gujarat", "label": "Gujarat" },
  { "value": "haryana", "label": "Haryana" },
  { "value": "himachal-pradesh", "label": "Himachal Pradesh" },
  { "value": "jammu-and-kashmir", "label": "Jammu and Kashmir" },
  { "value": "jharkhand", "label": "Jharkhand" },
  { "value": "karnataka", "label": "Karnataka" },
  { "value": "kerala", "label": "Kerala" },
  { "value": "madhya-pradesh", "label": "Madhya Pradesh" },
  { "value": "maharashtra", "label": "Maharashtra" },
  { "value": "manipur", "label": "Manipur" },
  { "value": "meghalaya", "label": "Meghalaya" },
  { "value": "mizoram", "label": "Mizoram" },
  { "value": "nagaland", "label": "Nagaland" },
  { "value": "odisha", "label": "Odisha" },
  { "value": "punjab", "label": "Punjab" },
  { "value": "rajasthan", "label": "Rajasthan" },
  { "value": "sikkim", "label": "Sikkim" },
  { "value": "tamil-nadu", "label": "Tamil Nadu" },
  { "value": "telangana", "label": "Telangana" },
  { "value": "tripura", "label": "Tripura" },
  { "value": "uttar-pradesh", "label": "Uttar Pradesh" },
  { "value": "uttarakhand", "label": "Uttarakhand" },
  { "value": "west-bengal", "label": "West Bengal" },
]

const genders = [
  { "value": "male", "label": "Male" },
  { "value": "female", "label": "Female" },
  { "value": "other", "label": "Others" },
]

const libraryUserRoles = [
  { "value": 1, "label": "Admin" },
  { "value": 2, "label": "Librarian" },
  { "value": 3, "label": "Staff" }  
]

const AccessionStatus = [
  { "value": "AVAILABLE", "label": "Available", "color": "success"},
  { "value": "ISSUED", "label": "Issued", "color": "warning"},  
  { "value": "LOST", "label": "Lost", "color": "error"},
  { "value": "DAMAGED", "label": "Damaged", "color": "error"},
  { "value": "DISPOSED", "label": "Disposed", "color": "error"}
]

const CirculationStatus = [
  { "value": "ISSUED", "label": "Issued", "color": "warning"},
  { "value": "RE_ISSUED", "label": "Re-Issue", "color": "warning"},
  { "value": "RETURNED", "label": "Returned", "color": "success"},
  { "value": "LOST", "label": "Lost", "color": "error"},
  { "value": "DAMAGED", "label": "Damaged", "color": "error"},
  { "value": "DISPOSED", "label": "Disposed", "color": "error"}
]

const AccessionLanguages = [
  { "value": "ENGLISH", "label": "English"},
  { "value": "HINDI", "label": "Hindi"},
  { "value": "TAMIL", "label": "Tamil"},
  { "value": "TELUGU", "label": "Telugu"},
  { "value": "MALAYALAM", "label": "Malayalam"},
  { "value": "KANNADA", "label": "Kannada"},
  { "value": "MARATHI", "label": "Marathi"},
  { "value": "BENGALI", "label": "Bengali"},
  { "value": "PUNJABI", "label": "Punjabi"},
  { "value": "GUJARATI", "label": "Gujarati"},
  { "value": "ODIA", "label": "Odia"},
  { "value": "ASSAMESE", "label": "Assamese"},
  { "value": "URDU", "label": "Urdu"},
  { "value": "NEPALI", "label": "Nepali"},
  { "value": "SANSKRIT", "label": "Sanskrit"},
  { "value": "OTHERS", "label": "Others"}
]

const successList = ["complete", "captured", "success", "done", "finish", "finish", "paid"];
const warningList = ["pending", "waiting", "hold", "hold"];
const errorList = ["failed", "reject", "cancel", "cancel"];

module.exports = {
  states,
  genders,
  libraryUserRoles,
  AccessionStatus,
  CirculationStatus,
  AccessionLanguages,
  successList,
  warningList,
  errorList 
}